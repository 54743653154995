<template>
<number-input>
  {{ checkpoint.handler_payload.label }} <span class="has-text-weight-light" v-if="checkpoint.handler_payload.units">({{ checkpoint.handler_payload.units }})</span>
</number-input>  
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  
  computed: mapGetters('checkpoint', [
    'checkpoint'
  ])

}
</script>